import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Close from '@mui/icons-material/Close';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { Zoom, Fade, Slide as MuiSlide } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ProductInfo } from '@/app/components/ProductInfo';

const TryOnResultCard = ({ tryOnResult }: any) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <Fade in={!tryOnResult.base64TryOnResult}>
        <Box
          sx={{
            position: 'absolute',
            backgroundColor: 'rgba(0,0,0,0.5)',
            width: '100%',
            height: '100%',
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              display: 'inline-flex',
              flexDirection: 'column',
            }}
          >
            <CircularProgress
              variant="determinate"
              value={tryOnResult.progress}
              sx={{
                color: '#fff',
                circle: {
                  fill: 'rgba(0, 0, 0, 0.5)',
                  strokeWidth: 1.5,
                },
              }}
              size={120}
            />

            <Box
              sx={{
                fontSize: '2rem',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {tryOnResult.progress || 0}%
            </Box>
          </Box>
          <Box
            sx={{
              mt: '12px',
              fontSize: '1.6rem',
              visibility: tryOnResult.progress !== 100 ? 'visible' : 'hidden',
            }}
          >
            {t('progress')}
          </Box>
        </Box>
      </Fade>

      <Box
        sx={{
          width: '100%',
          height: '100%',
          backgroundImage: `url(${tryOnResult.product.image_url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: '4px',
        }}
      >
        {!!tryOnResult.base64TryOnResult && (
          <img
            style={{
              borderRadius: '4px',
            }}
            src={tryOnResult.base64TryOnResult}
            alt="try on photo"
          />
        )}
      </Box>
    </Box>
  );
};

export const TryOnGallery = (props: any) => {
  const { t } = useTranslation();
  const [galleryDialogOpen, setGalleryDialogOpen] = useState(false);
  const [galleryImageIndex, setGalleryImageIndex] = useState(0);
  const {
    subjectFrame,
    closeGallery,
    tryOnList,
    handleTryOn,
    wishList,
    handleToggleInWishlist,
  } = props;
  const [productInfoOpen, setProductInfoOpen] = useState(false);

  const openGalleryDialog = (index?: number) => {
    setGalleryDialogOpen(true);
    setGalleryImageIndex(index ?? 0);
  };

  const closeGalleryDialog = () => {
    setGalleryDialogOpen(false);
    setGalleryImageIndex(0);
    setProductInfoOpen(false);
  };

  const processingInProgress = tryOnList.some(
    (tryOnResult: any) => !tryOnResult.base64TryOnResult,
  );

  const checkIsInWishlist = (product: any) => {
    return !!wishList.find((el: any) => el._id === product._id);
  };

  const checkIsInGallery = (product: any) => {
    return !!tryOnList.find((el: any) => el.product.datz === product._id);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        height: '100vh',
        width: '100vw',
        zIndex: '800',
      }}
    >
      <Zoom in>
        <Box
          onClick={closeGallery}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            color: '#fff',
            position: 'absolute',
            top: '56px',
            left: '0',
            zIndex: '99999',
            width: '200px',
          }}
        >
          <img
            src="https://storage.gonilabs.com/assets/flow_return.png"
            width={64}
            height={64}
            alt="Go back"
          />
        </Box>
      </Zoom>

      <Dialog
        open={galleryDialogOpen}
        onClose={closeGalleryDialog}
        maxWidth="xl"
      >
        <Box
          sx={{
            width: '94vw',
            color: '#fff',
            '.nav': {
              width: '100px !important',
              height: '100px !important',
              background: 'rgba(0,0,0,0.5) !important',
              svg: {
                fill: '#fff !important',
              },
            },
            'button[data-type="next"]': {
              right: '12px !important',
            },
            'button[data-type="prev"]': {
              left: '12px !important',
            },
            '& .each-slide-effect': {
              height: '100%',
              minHeight: '1152px',
            },
            '& .each-slide-effect img': {
              width: '100%',
            },
          }}
        >
          <Slide
            defaultIndex={galleryImageIndex}
            canSwipe={!productInfoOpen}
            arrows={!productInfoOpen}
            autoplay={false}
            transitionDuration={200}
          >
            {tryOnList.map((tryOnResult: any = {}) => (
              <Box
                sx={{ position: 'relative', height: '100%' }}
                key={tryOnResult.task_id}
              >
                <IconButton
                  onClick={() => setProductInfoOpen(!productInfoOpen)}
                  sx={{
                    padding: '10px!important',
                    borderRadius: '40px !important',
                    position: 'absolute',
                    top: '20px',
                    right: '20px',
                    zIndex: 9,
                    backgroundColor: 'rgba(0,0,0,0.8) !important',
                    svg: {
                      fill: '#fff',
                      width: '64px',
                      height: '64px',
                    },
                  }}
                >
                  <Box pl={2} mr={2} sx={{ fontSize: '2.4rem' }}>
                    {t('productInfo')}
                  </Box>
                  {productInfoOpen ? <Close /> : <InfoOutlined />}
                </IconButton>
                <>
                  <div className="each-slide-effect" key={tryOnResult.task_id}>
                    <TryOnResultCard tryOnResult={tryOnResult} />

                    <Box
                      sx={{
                        position: 'absolute',
                        width: '100%',
                        zIndex: 9,
                        top: '120px',
                        overflow: 'hidden',
                        height: 'calc(100% - 120px)',
                      }}
                    >
                      <MuiSlide in={productInfoOpen} direction="up">
                        <Box
                          sx={{
                            background: 'rgba(19, 19, 19, 0.95)',
                            height: '100%',
                            overflow: 'scroll',
                            scrollbarWidth: 'none',
                          }}
                        >
                          <ProductInfo
                            sizeInfo={t('recommendedSizeComingSoon')}
                            tryOnTaskResults={tryOnList}
                            productInfoDetails={tryOnResult.parentProduct}
                            hidePrimaryAction={(productVariant: any) =>
                              checkIsInGallery(productVariant)
                            }
                            checkIsProductAddedToWishlist={(
                              productVariant: any,
                            ) => checkIsInWishlist(productVariant)}
                            handleToggleInWishlist={(productVariant: any) =>
                              handleToggleInWishlist(productVariant)
                            }
                            handleTryOn={(product: any) => {
                              handleTryOn({ [product._id]: product }, true);
                              closeGalleryDialog();
                            }}
                          />
                        </Box>
                      </MuiSlide>
                    </Box>
                  </div>
                </>
              </Box>
            ))}
          </Slide>
        </Box>
      </Dialog>

      {!!tryOnList.length && (
        <Box
          sx={{
            fontSize: '3rem',
            textAlign: 'center',
            width: '100%',
            top: '200px',
            position: 'absolute',
          }}
        >
          {processingInProgress ? t('resultsAboutToBeReady') : t('looksGood')}
        </Box>
      )}

      {!tryOnList.length && (
        <Box
          sx={{
            fontSize: '3rem',
            textAlign: 'center',
            width: '100%',
            top: '200px',
            position: 'absolute',
          }}
        >
          {t('noProcessedResults')}
        </Box>
      )}

      <Box
        sx={{
          position: 'absolute',
          top: '360px',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '40px',
          px: '50px',
          height: '74vh',
          overflow: 'auto',
          width: 'calc(100% - 170px)',
          alignContent: 'flex-start',
        }}
      >
        {tryOnList.map((tryOnResult: any, index: number) => (
          <Zoom in key={tryOnResult.task_id}>
            <Box key={tryOnResult.task_id} sx={{ mb: '40px' }}>
              <Box
                onClick={() => openGalleryDialog(index)}
                sx={{
                  position: 'relative',
                  width: '360px',
                  height: '480px',
                }}
              >
                <TryOnResultCard tryOnResult={tryOnResult} />
                <Box
                  className={'dir'}
                  sx={{
                    marginTop: '8px',
                    padding: 0,
                    fontSize: '1.8rem',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {tryOnResult.product.name}
                </Box>
              </Box>
            </Box>
          </Zoom>
        ))}
      </Box>
    </Box>
  );
};
