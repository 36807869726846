export const en = {
	termsAndConditions: 'Privacy Policy',
	termsAndConditionsTitle: 'Privacy Policy',
	termsAndConditionsContent:
		'Goni Labs mirror uses the camera as it required for us to provide our service. The information we collect during your use of the mirror is deleted after your session. We do not store facial images or any personal information such as full name, payment details, address, etc. Organizing and analyzing information for our growing community is a key component of our service. A large part of our service involves creating and using advanced technologies to customize, protect, and enhance our services on a large scale for a broad global community. Technologies like artificial intelligence and machine learning empower us to implement complex processes across all parts of our service. Automated technologies also help ensure the functionality and integrity of our service. Please note that your personal information is not distributed or shared with any entity or system that could identify you as a user at any stage. The information we collect and process is used to improve the company’s dressing capabilities, processed without facial images or any identifying information. For example, if you try on a garment in front of the mirror, we process the before-and-after images to improve our algorithm, but we do not know whose image it is or associate it with you at any stage.',
	clickToMeasure: 'Take your picture',
	photoCountdown: 'Your photo will be taken in {{countdown}} seconds',
	chooseYourPicture: 'Choose One Picture',
	continueToProductsList: 'Continue to Product Selection',
	retakePicture: 'Retake Picture',
	startTryOn: 'Start Virtual Try-On',
	productItemType: 'Item Type: {{item_type}}',
	productItemColor: 'Color: {{color}}',
	seeInWardrobe: 'View in Wardrobe',
	description: 'Description',
	gender: 'Gender',
	category: 'Category',
	color: 'Color',
	reshoot: 'New Picture',
	likedList: 'Favorites',
	wardrobe: 'Wardrobe',
	products: 'Products',
	progress: 'Progress',
	noProcessedResults: 'No results yet...',
	resultsAboutToBeReady: 'Soon you will know how it looks!',
	looksGood: 'Looking good!',
	noProductsInWishlist: 'No products added to favorites yet',
	reshotCacheClearingWarning: 'Picture retake will delete all your photos from the wardrobe, are you sure?',
	women: 'Women',
	men: 'Men',
	other: 'Other',
	recommendedSizeComingSoon: 'Recommended Size: Coming Soon',
	productInfo: 'Product Info',
};
