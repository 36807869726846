// @ts-nocheck

'use client';
import React, { useEffect, useRef, useState } from 'react';
import ToggleButtonList from './ToggleButtonList';

import {
  callApiGetColors,
  callApiGetVirtualTryOnTaskStatus,
  callApiSubmitVirtualTryOnTask,
  callApiGetItemTypes,
  callApiGetGarments,
} from '../common/api';
import VirtualGridProducts from './VirtualGridProducts';
import { Box, Fade, Zoom } from '@mui/material';
import SubjectFrames from './SubjectFrames';
import { TryOnGallery } from '@/app/components/TryOnGallery';

// @ts-ignore
import xorBy from 'lodash/xorBy';
import { useTranslation } from 'react-i18next';
import { WishList } from '@/app/components/WishList';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import CircularProgress from '@mui/material/CircularProgress';

import { shortcutTryOnResults } from './shortcutTryOnResults';

enum TryOnSteps {
  StepSelectImage,
  StepFilterProducts,
  StepWishlist,
  ShowTryOnGallery,
}

const ALLOWED_NUMBER_OF_PRODUCTS_TO_TRY_ON = 5;

const useTryOn = () => {
  // todo remove before pushing
  // const [tryOnTaskResults, setTryOnTaskResults] =
  //   useState<any[]>(shortcutTryOnResults);
  const [tryOnTaskResults, setTryOnTaskResults] = useState<any[]>([]);
  const tryOnProducts = async ({
    subjectImage,
    selectedProducts,
  }: {
    subjectImage: string;
    selectedProducts: any[];
  }) => {
    const base64Response = await fetch(subjectImage);
    const blob = await base64Response.blob();
    const file = new File([blob], 'subject_image', { type: 'image/jpeg' });

    const tasks = selectedProducts.map((selectedProduct) => {
      const product = selectedProduct.garments[0] ?? {};
      const productImgUrl = product.image_url as string;
      const productCategory = product.category as string;
      const shirtType = product.shirt_type as string;
      return {
        product,
        parentProduct: selectedProduct,
        promise: callApiSubmitVirtualTryOnTask(
          file,
          productImgUrl,
          productCategory,
          shirtType,
        ),
      };
    });

    tasks.forEach((task) => {
      task.promise?.then((taskResponse) => {
        if (taskResponse.status === 'success') {
          const intervalId = setInterval(async () => {
            const {
              status,
              message,
              data: { state, task_id, virtual_try_on_photo_b64, info = {} },
            } = await callApiGetVirtualTryOnTaskStatus(
              taskResponse.data.task_id,
            );

            if (status === 'error') {
              console.error('Error while trying on product', task_id, message);
              // just to cleanup the failed task from the list
              setTryOnTaskResults((prev) =>
                prev.filter(
                  (taskResult) =>
                    taskResult?.task_id !== taskResponse?.data?.task_id,
                ),
              );
              return clearInterval(intervalId);
            }

            if (state === 'PROGRESS') {
              setTryOnTaskResults((prev) =>
                prev.map((taskResult) => {
                  return taskResult.task_id === task_id
                    ? {
                        ...taskResult,
                        state,
                        progress: info.progress ?? 0,
                      }
                    : taskResult;
                }),
              );
            }

            if (state === 'COMPLETED' || info.progress === 100) {
              setTryOnTaskResults((prev) =>
                prev.map((taskResult, index) => {
                  return taskResult.task_id === task_id
                    ? {
                        ...taskResult,
                        state,
                        progress: 100,
                        base64TryOnResult: `data:image/jpeg;base64,${virtual_try_on_photo_b64}`,
                      }
                    : taskResult;
                }),
              );
              clearInterval(intervalId);
            }
          }, 1000);

          const taskData = {
            task_id: taskResponse.data.task_id,
            product: task.product,
            parentProduct: task.parentProduct,
            progress: 0,
            intervalId,
            base64TryOnResult: '',
            state: 'UNKNOWN',
          };

          setTryOnTaskResults((prev) => [taskData, ...prev]);
        }
      });
    });
  };

  useEffect(() => {
    return () => {
      tryOnTaskResults.forEach(({ intervalId }) => {
        clearInterval(intervalId);
      });
    };
  }, []);

  return { tryOnProducts, tryOnTaskResults };
};

const imageCacheHelper = new Set();

export default function VirtualCamera({
  subjectImages,
  goHome,
  handleTakePictureFinish,
  resetShortcutFlow,
  handleRetakePicture,
  isShortcutFlow,
}: any) {
  const { t } = useTranslation();
  const [tryOnStep, setTryOnStep] = useState<TryOnSteps>(
    TryOnSteps.StepSelectImage,
  );
  const { tryOnProducts, tryOnTaskResults } = useTryOn();

  const genders = [
    { value: 'W', label: t('women') },
    { value: 'M', label: t('men') },
    { value: '', label: t('other') },
  ];
  const [gender, setGender] = useState('M');

  const [color, setColor] = useState('');

  const [itemType, setItemType] = useState('');

  const [selectedSubjectImageIndex, setSelectedSubjectImageIndex] =
    useState(-1);
  const subjectImage = subjectImages[selectedSubjectImageIndex];

  const [pageSize] = useState(9);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    isFetching,
    isLoading,
    error,
    data = { items: [], totalPages: 0 },
  } = useQuery({
    queryKey: [
      'callApiGetGarments',
      { currentPage, pageSize, gender, itemType, color },
    ],
    queryFn: ({
      queryKey: [_, { currentPage, pageSize, gender, itemType, color }],
    }) => callApiGetGarments(currentPage, pageSize, gender, itemType, color),
    select: (data) => {
      const { items, pagination } = data;

      const { total_pages } = pagination;

      return { items, totalPages: total_pages };
    },
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (data.items.length) {
      data.items.forEach((item: any) => {
        const {
          garments: [first],
        } = item;
        const { image_url } = first ?? {};

        if (!imageCacheHelper.has(image_url)) {
          imageCacheHelper.add(image_url);
          const img = new Image();
          img.src = image_url;
        }
      });
    }
  }, [data.items]);

  useEffect(() => {
    // when gender changes we need to make sure to drop other filters to avoid unexisting combo/results
    setItemType('');
    setColor('');
  }, [gender]);

  const { data: colors = [] } = useQuery({
    queryKey: ['callApiGetColors', { gender }],
    queryFn: ({ queryKey: [_, { gender }] }) => callApiGetColors(gender),
    placeholderData: keepPreviousData,
  });

  const { data: itemTypes = [] } = useQuery({
    queryKey: ['callApiGetItemTypes', { gender }],
    queryFn: ({ queryKey: [_, { gender }] }) => callApiGetItemTypes(gender),
    placeholderData: keepPreviousData,
  });

  function handleContinue() {
    setTryOnStep(TryOnSteps.StepFilterProducts);
  }

  function handelSubjectRetake() {
    setSelectedSubjectImageIndex(-1);
    handleRetakePicture();
  }

  const handleTryOn = async (selectedProducts: any, skipLimit = false) => {
    if (!subjectImage) return;

    const selectedProductsList = Object.values(selectedProducts);

    if (!selectedProductsList.length) return;
    if (
      selectedProductsList.length > ALLOWED_NUMBER_OF_PRODUCTS_TO_TRY_ON &&
      !skipLimit
    )
      return;

    await tryOnProducts({
      subjectImage,
      selectedProducts: selectedProductsList,
    });
    setTryOnStep(TryOnSteps.ShowTryOnGallery);
  };

  const [wishList, setWishList] = useState<any[]>([]);

  useEffect(() => {
    // we want to reset the page when filter changes
    setCurrentPage(1);
  }, [itemType, color, gender]);

  const handleToggleInWishlist = (product: any) => {
    setWishList((prev) => xorBy(prev, [product], '_id'));
  };

  const handleGoToWishlist = () => {
    setTryOnStep(TryOnSteps.StepWishlist);
  };

  return (
    <div
      style={{
        display: 'flex',
        position: 'relative',
        height: '100vh',
        width: '100vw',
        zIndex: '800',
      }}
    >
      {tryOnStep == TryOnSteps.StepSelectImage && (
        <Fade in>
          <Box>
            <SubjectFrames
              subjectImages={subjectImages}
              selectedSubjectImageIndex={selectedSubjectImageIndex}
              setSelectedSubjectImageIndex={setSelectedSubjectImageIndex}
              handleRetake={handelSubjectRetake}
              handleContinue={handleContinue}
            />
          </Box>
        </Fade>
      )}

      <Fade in={tryOnStep !== TryOnSteps.StepSelectImage}>
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            zIndex: 99999,
            top: 0,
            right: 0,
            height: window.screen.height,
            backgroundColor: '#FF000000',
          }}
        >
          <Fade in={isFetching}>
            <CircularProgress
              sx={{
                margin: '0px auto 30px',
                top: 0,
                color: '#fff',
              }}
              size={64}
            />
          </Fade>

          <ToggleButtonList
            onPhotoRetake={goHome}
            isWishListOpen={tryOnStep == TryOnSteps.StepWishlist}
            isProductsListOpen={tryOnStep == TryOnSteps.StepFilterProducts}
            isTryOnOpen={tryOnStep == TryOnSteps.ShowTryOnGallery}
            onProductsListClick={() =>
              setTryOnStep(TryOnSteps.StepFilterProducts)
            }
            tryOnTaskResults={tryOnTaskResults}
            onTryOnGalleryClick={() =>
              setTryOnStep(TryOnSteps.ShowTryOnGallery)
            }
            colors={colors}
            color={color}
            setColor={setColor}
            itemTypes={itemTypes}
            itemType={itemType}
            setItemType={setItemType}
            genders={genders}
            gender={gender}
            setGender={setGender}
            wishList={wishList}
            onWishListClick={handleGoToWishlist}
          />
        </div>
      </Fade>

      {tryOnStep == TryOnSteps.StepFilterProducts && (
        <Fade in>
          <Box>
            <VirtualGridProducts
              products={data.items}
              totalPages={data.totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              selectionLimit={ALLOWED_NUMBER_OF_PRODUCTS_TO_TRY_ON}
              wishList={wishList}
              handleToggleInWishlist={handleToggleInWishlist}
              handleTryOn={handleTryOn}
              goToGallery={() => setTryOnStep(TryOnSteps.ShowTryOnGallery)}
              selectedPhoto={subjectImages[selectedSubjectImageIndex]}
              tryOnTaskResults={tryOnTaskResults}
            />
          </Box>
        </Fade>
      )}

      {tryOnStep == TryOnSteps.ShowTryOnGallery && (
        <Fade in>
          <Box>
            <TryOnGallery
              handleTryOn={handleTryOn}
              closeGallery={() => setTryOnStep(TryOnSteps.StepFilterProducts)}
              subjectFrame={subjectImage}
              tryOnList={tryOnTaskResults}
              wishList={wishList}
              handleToggleInWishlist={handleToggleInWishlist}
            />
          </Box>
        </Fade>
      )}

      {tryOnStep == TryOnSteps.StepWishlist && (
        <Fade in>
          <Box>
            <WishList
              wishList={wishList}
              handleTryOn={handleTryOn}
              closeWishlist={() => setTryOnStep(TryOnSteps.StepFilterProducts)}
              goToGallery={() => setTryOnStep(TryOnSteps.StepFilterProducts)}
              tryOnTaskResults={tryOnTaskResults}
              handleUpdateWishlist={setWishList}
            />
          </Box>
        </Fade>
      )}
    </div>
  );
}
